import { useAiIssueCreationEnablementState } from '../providers/IssueCreateSidePanelContextProvider';

import { getIsCreateIssueWithAiEnabledByUser } from './localStorageHelpers';

export const useIsAIIssueCreationEnabled = () => {
	const aiIssueCreationEnablement = useAiIssueCreationEnablementState();
	const isAiEnabled = aiIssueCreationEnablement?.isEnabled ?? false;
	const isCreateIssueWithAiEnabledByUser = getIsCreateIssueWithAiEnabledByUser();

	return isAiEnabled && isCreateIssueWithAiEnabledByUser;
};
