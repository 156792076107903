import { getLogger } from '@confluence/logger';

import { convertSelectionToLegacyFormat } from './convertSelectionToLegacyFormat';

const logger = getLogger('highlight-action');

export const isHighlightValidForInlineComment = (container, contentId) => {
	const selection = window.getSelection();

	// getSelection() only returns null for iframes in firefox; in all other cases the type field is set to "None"
	if (!selection || selection?.type === 'None') {
		return false;
	}

	let legacyHighlight;

	try {
		legacyHighlight = convertSelectionToLegacyFormat(container, selection.getRangeAt(0), contentId);
	} catch (err) {
		logger.error`An error occurred while highlighting content - ${err}`;
	}

	if (!legacyHighlight || !legacyHighlight.searchText) {
		return false;
	}

	const {
		searchText: { selectedText, index, numMatches },
	} = legacyHighlight;

	/*
	 * A valid highlight contains:
	 *
	 *   A selection that has a length (non-empty)
	 *   A matched index (-1 means not found)
	 *   A positive non-zero number of matches
	 *   A match index that is less than the number of matches
	 *
	 */
	return (
		selectedText.replace(/\n/g, '').length > 0 &&
		index !== -1 &&
		numMatches > 0 &&
		index < numMatches
	);
};

export const isHighlightValidForBulkCreateJiraIssues = () => {
	const selection = window.getSelection();
	if (!selection || selection?.type === 'None') {
		return false;
	}

	const range = getRange();

	if (!range) {
		return false;
	}

	const parentElement = getContainingElement(range);

	return parentElement instanceof HTMLElement && Boolean(parentElement.closest('table'));
};

export const textNormalize = (s: string) => {
	/*
   CONF-36789: on IE, selectionRange.toString() always convert all &nbsp; to normal space
   on other browsers, it does not. So we must convert manually to made consistency across all browsers
   */
	return s.replace(/\u00a0/g, '\u0020');
};

// ignoring DOM/window related functionality in this file
export const getRange = () => {
	const selection = window.getSelection();

	// no selection made webkit
	if (!selection || selection.isCollapsed) {
		return null;
	}

	const selectionRange = selection.getRangeAt(0);

	// Don't show popup if selection is white space
	if (!String(selectionRange).trim()) {
		return null;
	}

	return selectionRange;
};

export const getScrollParent = (node) => {
	const TEXT_NODE_TYPE = 3;

	if (!node) {
		return null;
	}

	if (node.nodeType !== TEXT_NODE_TYPE && node.scrollWidth > node.clientWidth) {
		return node;
	} else {
		return getScrollParent(node.parentNode);
	}
};

export const findStickyHeader = (node) => {
	const ELEMENT_NODE_TYPE = 1;

	if (!node) {
		return null;
	}

	if (node.nodeType === ELEMENT_NODE_TYPE) {
		if (node.classList.contains('pm-table-sticky-wrapper')) {
			return node;
		}
	}

	if (node.parentElement) {
		return findStickyHeader(node.parentElement);
	}

	return null;
};

export const getContainingElement = (selectionRange: Range) => {
	if (selectionRange.commonAncestorContainer) {
		const selectRangeElement = selectionRange.commonAncestorContainer;
		if (selectRangeElement.nodeType === 3) {
			//Is TextNode
			return selectRangeElement.parentNode;
		}
		return selectRangeElement;
	}
	return null;
};
