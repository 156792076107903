import type { ReactElement, FC, ComponentType, ReactNode } from 'react';
import React, {
	Fragment,
	useState,
	useEffect,
	useRef,
	useContext,
	useLayoutEffect,
	useMemo,
	useCallback,
} from 'react';
import type { ApolloError } from 'apollo-client';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl-next';
// We have deprecated unstated. Please use react-sweet-state instead

import { Subscribe } from 'unstated';
import uuid from 'uuid/v4';
import { styled } from '@compiled/react';

import Button from '@atlaskit/button';
import NewButton from '@atlaskit/button/new';
import AddIssue from '@atlaskit/icon/core/add';
import AddCommentIcon from '@atlaskit/icon/core/comment';
import { RovoIcon } from '@atlaskit/logo';
import type { TooltipPrimitiveProps } from '@atlaskit/tooltip';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';
import { ModalTransition, SpotlightTransition, SpotlightTarget } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { AIEventsInstrumentationProvider } from '@atlassian/ai-analytics';
import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';
import { RovoChatHighlightActions } from '@atlassian/conversation-assistant-ui-components';
import {
	CONVERSATION_ASSISTANT_CONTAINER_ID,
	CONVERSATION_ASSISTANT_CONTAINER_WIDTH,
} from '@atlassian/conversation-assistant/utils';

import { useObjectSidebar } from '@confluence/object-sidebar-api';
import {
	GeneralShortcutListener,
	ShortcutVisualizer,
	UNIVERSAL_OPEN_COMMENT_EDITOR,
} from '@confluence/shortcuts';
import {
	READING_AIDS_EXPERIENCE,
	VIEW_PAGE_CONTEXT_MENU_EXPERIENCE,
	ExperienceStart,
	ExperienceSuccess,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { WebItemLocation, ACTION_PANEL } from '@confluence/web-item-location';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';
import { FORGE_MODULE_CONTEXT_MENU, LazyForgeUIExtensions } from '@confluence/forge-ui';
import type { ForgeUIExtensionType } from '@confluence/forge-ui';
import { ContentBodyContext } from '@confluence/content-body';
import { BannerStateContainer } from '@confluence/banners';
import { useSearchSessionId } from '@confluence/search-session';
import {
	useCommentsContentState,
	useInlineCommentsDispatchContext,
	useReattachComment,
	useReattachCommentDispatch,
} from '@confluence/comment-context';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import { useAddCommentPermissionCheck } from '@confluence/comments-hooks';
import { DialogsStateContainer } from '@confluence/dialogs';
import { LoadableLazy } from '@confluence/loadable';
import { getLogger } from '@confluence/logger';
import { ReadingAidsPopup } from '@confluence/contextual-reading-aids/entry-points/readingAidsPopup';
import { ReadingAidsChangeboardingModal } from '@confluence/contextual-reading-aids/entry-points/ReadingAidsChangeboardingModal';
import {
	ReadingAidsChangeboardingSpotlight,
	READING_AIDS_CHANGEBOARDING_SPOTLIGHT_ID,
} from '@confluence/contextual-reading-aids/entry-points/ReadingAidsChangeboardingSpotlight';
import { READING_AIDS_MAX_WORD_COUNT } from '@confluence/contextual-reading-aids/entry-points/reading-aids-utils';
import { useReadingAidsActions } from '@confluence/contextual-reading-aids/entry-points/ReadingAidsStore';
import { useFeatureDiscovery } from '@confluence/feature-discovery';
import { isErrorMarkedAsHandled } from '@confluence/graphql-error-processor';
import {
	ISSUE_CREATE_SIDE_PANEL_ID,
	IssueCreateWarningDialog,
	SingleIssueCreateProvidersWrapper,
} from '@confluence/issue-create-side-panel';
import { useRightSidebarContext } from '@confluence/page-layout-context';
import { useIsAIEnabledForContent } from '@confluence/ai-common/entry-points/useIsAIEnabledForContent';

import { CreateJiraIssueActions } from './CreateJiraIssueActions';
import { Popup } from './Popup';
import { popupPortalContainerId } from './HighlightActionsProvider';
import { createJiraIssueSummaryHandle } from './builtInButtonHandlers';
import { HighlightActionsContext } from './HighlightActionsContext';
import {
	isHighlightValidForInlineComment,
	textNormalize,
	getRange,
	findStickyHeader,
	isHighlightValidForBulkCreateJiraIssues,
} from './highlightActionsUtils';
import { OtherActions } from './OtherActions';
import { convertSelectionToLegacyFormat } from './convertSelectionToLegacyFormat';
import {
	BuiltInActions,
	DisabledButtonWrapper,
	DisabledButtonOverlay,
	Divider,
} from './highlightActionsStyled';
import { AtlassianIntelligenceIcon } from './AtlassianIntelligenceIcon';
import type { HighlightActionsContextState } from './HighlightActionsContext';
import type {
	CreateSingleAiJiraIssueHandleParams,
	CreateSingleJiraIssueHandleParams,
} from './createJiraIssueHandlers';
import {
	createSingleAiJiraIssueHandle,
	createSingleJiraIssueHandle,
} from './createJiraIssueHandlers';

const POPUP_MOUSEUP_DISTANCE_THRESHOLD = 100;
const BUILT_IN_ITEMS = [
	'com.atlassian.confluence.plugins.confluence-jira-content:create-JIRA-issue-summary',
];

const DROPDOWN_EXCLUDED_ITEMS = [
	'com.atlassian.confluence.plugins.confluence-inline-comments:create-inline-comment',
	'com.atlassian.confluence.plugins.confluence-jira-content:create-JIRA-issue-summary',
];

const i18n = defineMessages({
	commentTooltip: {
		id: 'highlight-actions.inline.comment.tooltip',
		defaultMessage: 'Comment',
		description: 'Tooltip for the inline comment button in the highlight actions menu',
	},
});

const logger = getLogger('inline-comment');

const ForgeAction = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ForgeAction" */ './ForgeAction')).ForgeAction,
});

export const focusCommentButton = (evt: KeyboardEvent) => {
	const highlightActionsToolbar = document.querySelector('[data-testid="highlightActionsPopup"]');
	const focusedElement = document.activeElement;
	if (
		highlightActionsToolbar &&
		!highlightActionsToolbar?.contains(focusedElement) &&
		evt.key === 'Tab' &&
		!evt.ctrlKey &&
		!evt.altKey
	) {
		evt.preventDefault();
		(document.querySelector('[data-testid="createInlineCommentButton"]') as HTMLElement)?.focus();
	}
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomLineWrapTooltip = styled(TooltipPrimitive)({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	padding: `${token('space.025')} ${token('space.075')}`,
	gap: token('space.100'),

	position: 'relative',

	background: token('color.background.neutral.bold'),
	borderRadius: '3px',

	color: token('color.text.inverse'),
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.regular'),
});

export type SelectionRect = {
	top: number;
	left: number;
	width: number;
	height: number;
};

export type ActiveSelection = {
	selectedText: string;
	selectedRange: Range;
};

type Position = {
	top: number;
	left: number;
};

type HighlightActionsProps = {
	contentId: string;
	children?: ReactNode;
	hasInlineComments?: boolean;
	actions?: any;
	lastModifiedDate?: string | null;
	isArchived?: boolean;
	contentStatusError?: ApolloError;
	isAnnotationAllowed?: boolean;
	range?: Range;
	onCommentButtonPress?: () => void;
	isFabric?: boolean;
	contentType?: 'page' | 'blogpost' | 'whiteboard' | 'database' | 'embed';
	inlineNodeTypes?: string[];
};

type HighlightActionsStates = {
	show: boolean;
	top: number;
	left: number;
	activeSelection: ActiveSelection | null;
	selectionRect: SelectionRect | null;
	forgeExtension: ForgeUIExtensionType | null;
	isReadingAidsOpen: boolean;
	readingAidsChangeboardingStep:
		| 'disabled' // changeboarding is not enabled
		| 'idle' // changeboarding is enabled, but not started
		| 'modal-word-count' // step 1, option a: show modal if word count is over 5
		| 'modal-undefined' // step 1, option b: show modal if text selection is not defineable
		| 'spotlight'; // step 2: show spotlight on "define" button
} & HighlightActionsContextState;

export const getComponentsWithDivider = (components: ReactElement[], hasOtherActions: boolean) => {
	return components
		.reduce((result: ReactElement[], component, i) => {
			// Add the current component
			result.push(component);
			// Add a divider after each component except the last one
			if (i < components.length - 1) {
				result.push(<Divider key={`divider-${i}`} data-testid="divider" />);
			}
			return result;
			// If there are other actions, add a divider at the end
		}, [])
		.concat(hasOtherActions ? <Divider key="divider-last" data-testid="divider-last" /> : []);
};

export type CreateIssueRecordedProps =
	| CreateSingleAiJiraIssueHandleParams
	| CreateSingleJiraIssueHandleParams
	| undefined;

export const HighlightActions: FC<HighlightActionsProps> = ({
	contentId,
	children,
	hasInlineComments = true,
	actions,
	lastModifiedDate,
	isArchived,
	contentStatusError,
	range,
	isAnnotationAllowed,
	onCommentButtonPress,
	isFabric,
	contentType,
	inlineNodeTypes,
}) => {
	const [{ isObjectSidebarShown }, { hideObjectSidebar }] = useObjectSidebar();

	const [actionStates, setActionStates] = useState<HighlightActionsStates>({
		firstHighlightShown: false,
		show: false,
		top: 0,
		left: 0,
		forgeExtension: null,
		activeSelection: null,
		selectionRect: null,
		isReadingAidsOpen: false,
		readingAidsChangeboardingStep: 'disabled',
	});
	const [position, setPosition] = useState<Position | null>(null);

	const { hasContentChanged } = useCommentsContentState();
	const { createComment } = useInlineCommentsDispatchContext();
	const { commentToReattach, isInReattachMode } = useReattachComment();
	const { setReattachHighlight } = useReattachCommentDispatch();
	const { lastModificationDate } = useContext(ContentBodyContext);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const { canAddComments, contentQueryError } = useAddCommentPermissionCheck(contentId);
	const [{ searchSessionId, additionalAnalytics }] = useSearchSessionId();

	const rightSidebarContext = useRightSidebarContext();
	const [isWarningDialogOpen, setIsWarningDialogOpen] = useState<Boolean>(false);
	const [recordedClick, setRecordedClick] = useState<CreateIssueRecordedProps>();

	const { setDefineDefinitionOpen } = useReadingAidsActions();

	const { isAIEnabledForContent, isRovoEnabledForContent } = useIsAIEnabledForContent({
		contentId,
	});

	const {
		firstHighlightShown,
		show,
		top,
		left,
		forgeExtension,
		activeSelection,
		selectionRect,
		isReadingAidsOpen,
		readingAidsChangeboardingStep,
	} = actionStates;

	const mountedRef = useRef(false);
	const screenEventFiredRef = useRef(false);
	const highlightActionsShownRef = useRef(false);

	const content = useRef<HTMLDivElement | null>(
		typeof document !== 'undefined' ? document.querySelector('div#content') : null,
	);

	const [showReadingAidsChangeboarding, stopReadingAidsChangeboarding] = useFeatureDiscovery(
		'highlight-actions.reading-aids.changeboarding',
	);

	const isReadingAidsChangeboardingActive = readingAidsChangeboardingStep.match(/^modal|spotlight/);

	const highlightActionsContextValue = useMemo(
		() => ({ firstHighlightShown }),
		[firstHighlightShown],
	);

	// start the reading aids changeboarding
	useEffect(() => {
		setActionStates((prevState) => ({
			...prevState,
			readingAidsChangeboardingStep:
				showReadingAidsChangeboarding && isAIEnabledForContent ? 'idle' : 'disabled',
		}));
	}, [showReadingAidsChangeboarding, isAIEnabledForContent]);

	useEffect(() => {
		// Manually trigger resize to ensure spotlight is properly aligned
		window.setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
	}, [show]);

	// freeze the user's selection while reading aids changeboarding is active
	useEffect(() => {
		const akPortalContainer = document.querySelector<HTMLDivElement>('.atlaskit-portal-container');
		if (akPortalContainer) {
			if (isReadingAidsChangeboardingActive) {
				akPortalContainer.style.userSelect = 'none';
				return () => {
					akPortalContainer.style.userSelect = 'auto';
				};
			} else {
				akPortalContainer.style.userSelect = 'auto';
			}
		}
	}, [isReadingAidsChangeboardingActive]);

	useLayoutEffect(() => {
		mountedRef.current = true;
		return () => {
			mountedRef.current = false;
		};
	}, []);

	useEffect(() => {
		window.addEventListener('mouseup', onMouseUp);

		window.addEventListener('highlight-actions.container.resized', onContainerResized);

		return () => {
			window.removeEventListener('mouseup', onMouseUp);

			window.removeEventListener('highlight-actions.container.resized', onContainerResized);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId, commentToReattach, isReadingAidsChangeboardingActive]);

	// Close reading aids popup when navigating to another page
	useEffect(() => {
		return () => {
			void setDefineDefinitionOpen({ defineDefinitionOpen: false });

			setActionStates((prevState) => ({
				...prevState,
				isReadingAidsOpen: false,
			}));
		};
	}, [contentId, setDefineDefinitionOpen]);

	const onContainerResized = () => {
		setActionStates((prevState) => ({
			...prevState,
			show: false,
			forgeExtension: null,
		}));
	};

	const getLegacyConvertedSelectionObj = () => {
		let selectionObj = {
			numMatches: 0,
			matchIndex: -1,
			selectedText: '',
			timestamp: 0,
		};

		const selection = window.getSelection();

		if (selection) {
			const range = selection.getRangeAt(0);
			const legacySelection = convertSelectionToLegacyFormat(content.current, range, contentId);

			const {
				searchText: { numMatches, index: matchIndex, selectedText },
			} = legacySelection;

			selectionObj = {
				numMatches,
				matchIndex,
				selectedText,
				timestamp: Date.now(),
			};

			/**
			 * We generate the annotation step for the upcoming new collab service
			 * to consume. This means we will eventually started sending markerRef
			 * ids from the FE.
			 */
			try {
				const markerRef = commentToReattach ? commentToReattach.inlineMarkerRef : uuid();

				if (actions) {
					const proseMirrorStep = actions.annotate(range, markerRef, 'inlineComment');

					const {
						step: { from, to, mark },
					} = proseMirrorStep;

					selectionObj['step'] = {
						from,
						to,
						mark: {
							type: 'annotation',
							attrs: {
								key: mark.attrs?.annotationType,
								value: mark.attrs?.id,
							},
						},
					};
				}
			} catch (e) {
				logger.error`An error occurred when generating a step - ${e}`;
			}
		}

		return selectionObj;
	};

	function getScrollParent(node: any): any {
		if (!node) {
			return null;
		}

		if (node.nodeType !== Node.TEXT_NODE && node.scrollWidth > node.clientWidth) {
			return node;
		} else {
			return getScrollParent(node.parentNode);
		}
	}

	function getParentNode(node: any): HTMLElement | null {
		if (!node) {
			return null;
		}

		if (node.nodeType !== Node.TEXT_NODE) {
			return node;
		} else {
			return getParentNode(node.parentNode);
		}
	}

	const getPosition = (range: Range) => {
		const contentRect = getParentNode(range.commonAncestorContainer)!.getBoundingClientRect();

		const portalContainer = document.querySelector(`#highlight-actions-portal-container`);

		const portalRect = portalContainer?.getBoundingClientRect() || {
			left: 0,
			top: 0,
		};

		const rect = range.getBoundingClientRect();

		const { left: leftRect, top: topRect, width, height } = rect;

		// Popup can't be rendered outside the body area
		let leftLimit = contentRect.left;
		let rightLimit = contentRect.right;

		// If the selected text has a parent that is scrollable for example like text inside table.
		// The popup is limited to be shown within the scrollable area.
		const firstParentThatCanScroll = getScrollParent(range.startContainer);
		if (firstParentThatCanScroll) {
			const scrollParentRect = firstParentThatCanScroll.getBoundingClientRect();
			leftLimit = Math.max(leftLimit, scrollParentRect.left);
			rightLimit = Math.min(rightLimit, scrollParentRect.right);
		}

		let x = width / 2 + leftRect;
		if (!fg('kd_definitions_skip_displacement_computation')) {
			x = Math.max(leftLimit, Math.min(rightLimit, x));
		}

		return {
			pos: {
				left: x - portalRect.left,
				top: topRect - portalRect.top,
			},
			selectionRect: {
				left: x - portalRect.left,
				top: topRect - portalRect.top,
				width,
				height,
			},
		};
	};

	useEffect(() => {
		if (range) {
			const { pos, selectionRect: selectionRectangle } = getPosition(range);
			highlightActionsShownRef.current = false;
			setPosition(pos);
			setActionStates((prevState) => ({
				...prevState,
				selectionRect: selectionRectangle,
				show: true,
			}));
		} else {
			setPosition(null);
			setActionStates((prevState) => ({
				...prevState,
				show: false,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [range]);

	const prepareAndSetHighlightForReattachMode = () => {
		const convertedSelectionObj = getLegacyConvertedSelectionObj();

		// Only set the highlight if it's valid
		if (convertedSelectionObj.numMatches > 0 && convertedSelectionObj.matchIndex !== -1) {
			setReattachHighlight(convertedSelectionObj);
		}
	};

	const isDatasourceHighlighted = (range: Range) => {
		const datasourceContainers = document.querySelectorAll('.datasource-table');

		return Array.from(datasourceContainers).some(
			(container) =>
				container.contains(range.startContainer) || container.contains(range.endContainer),
		);
	};

	const onMouseUp = (e) => {
		return requestAnimationFrame(() => {
			if (!mountedRef.current) {
				return;
			}

			const range = getRange();
			const portalContainer = document.querySelector(`#${popupPortalContainerId}`);

			// WS-1576/WS-2355 - Ignore any highlights in the Inline Comments sidebar
			const inlineCommentSidebarContainer = document.querySelector('#inline-comment-sidebar');

			// InlineCommentNudge handles its own content selection and opening of the Comment Editor
			if (e.target.closest('#inline-comment-nudge')) {
				return;
			}

			// freeze the actions popup if reading aids changeboarding is active
			if (isReadingAidsChangeboardingActive) {
				return;
			}

			// freeze the actions popup if the input is clicked in the rovo chat more options popup
			if (e.target.closest('#context-menu-rovo-chat-custom-prompt')) {
				return;
			}

			const isCommentButtonDisabledAndClicked = e.target.closest('button[disabled]');
			const isContextMenuOtherAppsButtonClicked = e.target.closest('#context-menu-other-apps');
			const isCreateJiraIssueButtonClicked = e.target.closest('#create-jira-issue-trigger');
			const isRovoChatMoreOptionsButtonClicked = e.target.closest(
				'#context-menu-rovo-chat-more-options',
			);
			const shouldHideContextMenu = !(
				isCommentButtonDisabledAndClicked ||
				isContextMenuOtherAppsButtonClicked ||
				isCreateJiraIssueButtonClicked ||
				isRovoChatMoreOptionsButtonClicked
			);
			if (
				!range ||
				!portalContainer ||
				!content.current ||
				inlineCommentSidebarContainer?.contains(range.startContainer) ||
				inlineCommentSidebarContainer?.contains(range.endContainer) ||
				isDatasourceHighlighted(range) ||
				(!content.current.contains(e.target) &&
					content.current !== e.target &&
					shouldHideContextMenu)
			) {
				if (isInReattachMode()) {
					setReattachHighlight(null);
				}
				setActionStates((prevState) => ({ ...prevState, show: false }));
				return;
			}

			// COMMENTS-332 - In reattach mode, skip the measuring of the screen, just set the mode with the highlight
			if (isInReattachMode()) {
				prepareAndSetHighlightForReattachMode();
				setActionStates((prevState) => ({ ...prevState, show: false }));
				return;
			}

			const portalRect = portalContainer.getBoundingClientRect();
			const contentRect = content.current.getBoundingClientRect();
			const rects = range.getClientRects();
			if (!rects || !rects.length) {
				setActionStates((prevState) => ({ ...prevState, show: false }));
				return;
			}

			// Find first selection area that width is not 0
			// Sometimes there is a chance that user is selecting an empty DOM node.
			const rect = Array.from(rects).find((rect) => rect.width !== 0 && rect.height !== 0);

			if (rect) {
				const { left, right, top, width, height } = rect;
				const mouseUpX = e.clientX;
				// This is a LEGACY disable statement after rule addition. Use `Math.floor(width/2)` next time
				// eslint-disable-next-line no-bitwise
				const selectedLineCenter = left + (width >> 1);

				// Popup can't be rendered outside the body area
				let leftLimit = contentRect.left;
				let rightLimit = contentRect.right;

				// If the selected text has a parent that is scrollable for example like text inside table.
				// The popup is limited to be shown within the scrollable area.
				const firstParentThatCanScroll = getScrollParent(range.startContainer);
				if (firstParentThatCanScroll) {
					const scrollParentRect = firstParentThatCanScroll.getBoundingClientRect();
					leftLimit = Math.max(leftLimit, scrollParentRect.left);
					rightLimit = Math.min(rightLimit, scrollParentRect.right);
				}

				// Use the X-axis position if the center of selected line is POPUP_MOUSEUP_DISTANCE_THRESHOLD away from mouse up position.
				// POPUP_MOUSEUP_DISTANCE_THRESHOLD is an arbitrary number I made up.
				// The idea is the popup will always show up within POPUP_MOUSEUP_DISTANCE_THRESHOLD of the end of the selection.
				// Right near what user selects. However when the selection is too short - like a phase,
				// it will show up at the middle of the selection because it looks better.
				let x =
					Math.abs(mouseUpX - selectedLineCenter) > POPUP_MOUSEUP_DISTANCE_THRESHOLD &&
					left <= mouseUpX &&
					mouseUpX <= right
						? mouseUpX
						: selectedLineCenter;

				// However it is still bounded by the left and right boundary of the first line.
				// This is because the mouse up can be off screen.
				// For example: Make selection, then drag on a scroll bar.
				if (!fg('kd_definitions_skip_displacement_computation')) {
					x = Math.max(leftLimit, Math.min(rightLimit, x));
				}

				if (!isFabric) {
					highlightActionsShownRef.current = false;
				}

				setActionStates((prevState) => ({
					...prevState,
					firstHighlightShown: true,
					// COMMENTS-332 - Don't show the popup when a selection is made in reattach mode
					show: !isInReattachMode(),
					left: x - portalRect.left,
					top: top - portalRect.top,
					selectionRect: {
						left: x - portalRect.left,
						top: top - portalRect.top,
						width,
						height,
					},
				}));
			}
		});
	};

	const createReactInlineCommentHighlight = () => {
		if (isObjectSidebarShown) {
			hideObjectSidebar();
		}
		if (isFabric) {
			onCommentButtonPress && onCommentButtonPress();
		} else {
			// Show the sidebar when the "Comment" button is clicked with the selection object
			createComment(getLegacyConvertedSelectionObj());
		}
	};

	const handleCommentButtonClick = (dialogs) => {
		if (hasContentChanged) {
			dialogs.showDialog(CommentWarningDialog, {
				onConfirm: () => createReactInlineCommentHighlight(),
			});
		} else {
			createReactInlineCommentHighlight();
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'highlightActionsMenu',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'createInlineCommentFromHighlightActionsMenu',
				type: 'ui',
				attributes: {
					contentId,
					pageMode: 'view',
					searchSessionId,
					...additionalAnalytics,
				},
			},
		}).fire();
	};

	const extractNodeDetailsToString = (node?: Node | null) => {
		if (!node) {
			return '';
		}

		while (node?.nodeType === Node.TEXT_NODE || (node as HTMLElement).tagName === 'MARK') {
			node = node?.parentNode;
		}

		if (node) {
			const elem = node as HTMLElement;
			const classListStr = Array.from(elem.classList).join(',');

			let attrListStr = '';
			Object.entries(elem.dataset).forEach((attrEntry, idx) => {
				if (idx > 0) {
					attrListStr += ',';
				}

				// COMMENTS-1916 - Remove any emails that are in any values for attributes
				if (attrEntry.length > 1) {
					const field = attrEntry[0];
					if (field && field.includes('testMediaName')) {
						attrEntry[1] = '';
					}
				}

				attrListStr += attrEntry.join('=');
			});

			// Get the tag name and stringify the class list and attribute list
			return `${elem.tagName}${classListStr.length ? `.${classListStr}` : ''}${
				attrListStr.length ? `[${attrListStr}]` : ''
			}`;
		}

		return '';
	};

	const getCreateButtonDisabledMessage = (
		isContentArchived?: boolean,
		userCanAddComments?: boolean,
	) => {
		if (isContentArchived) {
			return (
				<FormattedMessage
					description="Tooltip for the unsupported inline comment for the archive page"
					id="highlight-actions.inline-comments.comment.unsupported.archive"
					defaultMessage="Comments can’t be added to archived items"
				/>
			);
		} else if (!userCanAddComments) {
			return (
				<FormattedMessage
					id="highlight-actions.inline-comments.comment.no.permission"
					description="Tooltip for when the user does not have permission to add inline comments"
					defaultMessage="{contentType, select, blogpost {You do not have permission to add comments to this blogpost} page {You do not have permission to add comments to this page} other {You do not have permission to add comments to this page}}"
					values={{ contentType }}
				/>
			);
		} else {
			return fg('editor_inline_comments_on_inline_nodes') ? (
				<FormattedMessage
					id="highlight-actions.inline-comments.comment.create.disabled"
					description="Error message to communicate to the user they can only do the current action in certain contexts"
					defaultMessage="You can only comment on text, headings, emojis, dates, mentions, links, and statuses."
				/>
			) : (
				<FormattedMessage
					id="highlight-actions.inline-comments.comment.unsupported"
					description="Tooltip for the unsupported inline comment on the content that contains an emoji, a macro, or an @mention"
					defaultMessage="Comments can only be added to text that doesn’t contain an emoji, a macro, or an @mention"
				/>
			);
		}
	};

	const CreateInlineCommentButton = () => {
		useEffect(() => {
			document.addEventListener('keydown', focusCommentButton);

			return () => {
				document.removeEventListener('keydown', focusCommentButton);
			};
		}, []);

		if (!content.current) {
			return null;
		}

		const isHighlightValid = isFabric
			? isAnnotationAllowed
			: isHighlightValidForInlineComment(content.current, contentId);

		// Cases where we want to disable the create button:
		// 1. The content is archived.
		// 2. The user does not have the "Comment - Add" permission.
		// 3. The highlight was invalid (over elements we don't support commenting on).
		// This order matters when communicating to users, as checking "Comment - Add" permission for archived pages
		// will return false.
		const isCreateButtonEnabled = !isArchived && canAddComments && isHighlightValid;

		// Check if the comment is on a selection that includes an non-text inline node
		const isNonTextInlineNodeIncludedInComment =
			(inlineNodeTypes ?? []).filter((nodeType) => nodeType !== 'text').length > 0;

		// Track the nodes in which the highlight was made on to send in the event
		if (!highlightActionsShownRef.current) {
			highlightActionsShownRef.current = true;
			try {
				const selection = window.getSelection();
				const startingNode = extractNodeDetailsToString(selection?.anchorNode);
				const endingNode = extractNodeDetailsToString(selection?.focusNode);

				// Track when the menu is shown to users and if it's disabled
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						source: 'highlightActionsMenu',
						action: 'shown',
						actionSubject: 'toolbar',
						actionSubjectId: 'highlightActionsMenu',
						attributes: {
							isDisabled: !isCreateButtonEnabled,
							pageMode: 'view',
							startingNode,
							endingNode,
							inlineNodeTypes,
							/**
							 * This attribute is used as the trigger to display an engagement platform promotion message
							 * when isNonTextInlineNodeIncludedInComment is true, and isDisabled is false,
							 * A spotlight/flag will be shown to the user to encourage them to comment on inline nodes.
							 */
							isNonTextInlineNodeIncludedInComment,
						},
					},
				}).fire();
			} catch (e) {
				logger.error`Unable to send highlight actions event; ${e}`;
			}
		}

		const createButton = (
			<Subscribe to={[DialogsStateContainer]}>
				{(dialogs: DialogsStateContainer) => {
					const commonCreateButtonProps = {
						'data-key':
							'com.atlassian.confluence.plugins.confluence-inline-comments:create-inline-comment', // Needed for integration tests
						isDisabled: !isCreateButtonEnabled,
						testId: 'createInlineCommentButton',
						onClick: () => {
							handleCommentButtonClick(dialogs);
						},
					};

					return (
						<>
							{fg('rovo_chat_in_confluence_contextual_menu') ? (
								<Button
									{...commonCreateButtonProps}
									appearance="subtle"
									iconBefore={<AddCommentIcon label="" />}
								>
									<FormattedMessage
										id="highlight-actions.inline-comments.comment"
										defaultMessage="Comment"
										description="Make a new comment"
									/>
								</Button>
							) : (
								<NewButton
									{...commonCreateButtonProps}
									appearance="subtle"
									iconBefore={() => <AddCommentIcon label="" />}
								>
									<FormattedMessage
										id="highlight-actions.inline-comments.comment"
										defaultMessage="Comment"
										description="Make a new comment"
									/>
								</NewButton>
							)}
							<GeneralShortcutListener
								accelerator={UNIVERSAL_OPEN_COMMENT_EDITOR}
								listener={(e) => {
									e.preventDefault();
									handleCommentButtonClick(dialogs);
								}}
							/>
						</>
					);
				}}
			</Subscribe>
		);

		// WS-2802 - We need to wrap the disabled button and put a transparent absolute overlay over the button
		//           in order to show the tooltip correctly as the disabled button swallows all events on the element
		//           and ADG doesn't allow tooltips on disabled buttons for accessibility reasons...
		return isCreateButtonEnabled ? (
			<Fragment key="inline-comment-button">
				<Tooltip
					content={
						<ShortcutVisualizer
							shortcut={UNIVERSAL_OPEN_COMMENT_EDITOR}
							contentBefore={formatMessage(i18n.commentTooltip)}
						/>
					}
				>
					{createButton}
				</Tooltip>
			</Fragment>
		) : (
			<Tooltip
				delay={0}
				position="top"
				content={getCreateButtonDisabledMessage(isArchived, canAddComments)}
			>
				<DisabledButtonWrapper key="inline-comment-button">
					<DisabledButtonOverlay />
					{createButton}
				</DisabledButtonWrapper>
			</Tooltip>
		);
	};

	const createJiraIssueButton = (completeKey: string) => {
		if (!content.current || isEmbeddedConfluence_DO_NOT_USE()) {
			return null;
		}

		if (fg('modernize_issue_creation_phase_1')) {
			return (
				<SingleIssueCreateProvidersWrapper>
					<AIEventsInstrumentationProvider
						config={{
							// Required configuration
							product: 'confluence',
							subproduct: 'jira',
							aiFeatureName: 'confluenceAiWorkCreation',
							proactiveGeneratedAI: 0,
							userGeneratedAI: 1,
							// Optional configuration
							source: 'highlightActionsMenu',
							skipAISessionValidation: true,
						}}
					>
						<CreateJiraIssueActions
							activeSelection={window.getSelection()}
							completeKey={completeKey}
							container={content.current}
							contentId={contentId}
							contentType={contentType}
							lastModified={lastModifiedDate || lastModificationDate}
							showCreateMultipleIssuesButton={isHighlightValidForBulkCreateJiraIssues()}
							setRecordedClick={setRecordedClick}
							setIsWarningDialogOpen={setIsWarningDialogOpen}
						/>
					</AIEventsInstrumentationProvider>
				</SingleIssueCreateProvidersWrapper>
			);
		}

		return fg('rovo_chat_in_confluence_contextual_menu') ? (
			<Button
				appearance="subtle"
				iconBefore={<AddIssue label="" />}
				data-key={completeKey}
				testId="createJiraIssueButton"
				onClick={createJiraIssueSummaryHandle(
					content.current,
					contentId,
					lastModifiedDate || lastModificationDate,
				)}
			>
				{fg('confluence-issue-terminology-refresh') ? (
					<FormattedMessage
						id="highlight-actions.create-issue-issue-term-refresh"
						defaultMessage="Create work item"
						description="Create a new Jira issue"
					/>
				) : (
					<FormattedMessage
						id="highlight-actions.create-issue"
						defaultMessage="Create issue"
						description="Create a new Jira issue"
					/>
				)}
			</Button>
		) : (
			<NewButton
				appearance="subtle"
				iconBefore={() => <AddIssue label="" />}
				data-key={completeKey}
				testId="createJiraIssueButton"
				onClick={createJiraIssueSummaryHandle(
					content.current,
					contentId,
					lastModifiedDate || lastModificationDate,
				)}
			>
				{fg('confluence-issue-terminology-refresh') ? (
					<FormattedMessage
						id="highlight-actions.create-issue-issue-term-refresh"
						defaultMessage="Create work item"
						description="Create a new Jira issue"
					/>
				) : (
					<FormattedMessage
						id="highlight-actions.create-issue"
						defaultMessage="Create issue"
						description="Create a new Jira issue"
					/>
				)}
			</NewButton>
		);
	};

	const createReadingAidsDefineButton = ({
		selectedRange,
		selectedText,
		isOverWordLimit,
	}: {
		selectedRange: Range | null;
		selectedText: string;
		isOverWordLimit: boolean;
	}) => {
		if (!content.current) {
			return null;
		}

		const isDisabled =
			(isOverWordLimit && readingAidsChangeboardingStep === 'disabled') || // only check word limit when changeboarding is disabled
			readingAidsChangeboardingStep === 'spotlight'; // always disable when spotlight is active

		const commonDefineButtonProps = {
			testId: 'defineButton',
			isDisabled,
			onClick: () => {
				if (!selectedRange) {
					return;
				}

				if (!selectedText) {
					return;
				}

				if (isAIEnabledForContent || isRovoEnabledForContent) {
					experienceTracker.start({
						name: READING_AIDS_EXPERIENCE,
						attributes: {
							contentId,
							source: 'defineButton',
						},
					});
				}

				void setDefineDefinitionOpen({ defineDefinitionOpen: true });

				setActionStates((prevState) => ({
					...prevState,
					...(isOverWordLimit && readingAidsChangeboardingStep === 'idle'
						? { readingAidsChangeboardingStep: 'modal-word-count' }
						: { isReadingAidsOpen: true }),
					show: false,
					activeSelection: {
						selectedText,
						selectedRange,
					},
				}));

				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						source: 'highlightActionsMenu',
						action: 'clicked',
						actionSubject: 'button',
						actionSubjectId: 'defineButton',
						type: 'ui',
						attributes: {
							contentId,
							isAIOptedIn: isAIEnabledForContent,
						},
					},
				}).fire();
			},
		};

		const defineButton = (
			<SpotlightTarget name={READING_AIDS_CHANGEBOARDING_SPOTLIGHT_ID}>
				{fg('rovo_chat_in_confluence_contextual_menu') ? (
					<Button
						{...commonDefineButtonProps}
						appearance="subtle"
						iconBefore={
							isRovoEnabledForContent ? (
								<RovoIcon size="small" />
							) : (
								<AtlassianIntelligenceIcon size="large" greyed={isDisabled} />
							)
						}
					>
						<FormattedMessage
							id="highlight-actions.define"
							defaultMessage="Define"
							description="Tooltip for button that opens the reading aids popup, which provides an AI-generated definition for the highlighted term"
						/>
					</Button>
				) : (
					<NewButton
						{...commonDefineButtonProps}
						appearance="subtle"
						iconBefore={() =>
							isRovoEnabledForContent ? (
								<RovoIcon size="small" />
							) : (
								<AtlassianIntelligenceIcon size="large" greyed={isDisabled} />
							)
						}
					>
						<FormattedMessage
							id="highlight-actions.define"
							defaultMessage="Define"
							description="Tooltip for button that opens the reading aids popup, which provides an AI-generated definition for the highlighted term"
						/>
					</NewButton>
				)}
			</SpotlightTarget>
		);

		return isDisabled ? (
			<Tooltip
				delay={0}
				position="top"
				content={
					<FormattedMessage
						id="highlight-actions.define.unsupported"
						description="Tooltip for the unsupported reading aids search on content that is over the word count limit"
						defaultMessage="Highlight a term, acronym, or abbreviation"
					/>
				}
				component={CustomLineWrapTooltip as ComponentType<TooltipPrimitiveProps>}
			>
				<DisabledButtonWrapper>
					<DisabledButtonOverlay />
					{defineButton}
				</DisabledButtonWrapper>
			</Tooltip>
		) : (
			defineButton
		);
	};

	const handleClickChat = useCallback(() => {
		const { panelId } = rightSidebarContext.getCurrent() || {};
		const isChatActive = panelId === CONVERSATION_ASSISTANT_CONTAINER_ID;
		if (!isChatActive) {
			rightSidebarContext.open(
				CONVERSATION_ASSISTANT_CONTAINER_ID,
				CONVERSATION_ASSISTANT_CONTAINER_WIDTH,
			);
		}
	}, [rightSidebarContext]);

	const renderBuiltInWebItemActions = (builtInWebItems, hasOtherActions: boolean) => {
		const baseComponents = [] as (JSX.Element | null)[];
		if (hasInlineComments) {
			baseComponents.push(<CreateInlineCommentButton />);
		}

		const selectedRange = getRange();
		const selectedText = textNormalize(String(window.getSelection())).replace(/\n/, ' ').trim();
		const wordCount = selectedText.split(/\s+/).length;
		const isOverWordLimit = wordCount > READING_AIDS_MAX_WORD_COUNT;

		const showReadingAidsDefineButton = isAIEnabledForContent || isRovoEnabledForContent;

		if (isRovoEnabledForContent && fg('rovo_chat_in_confluence_contextual_menu')) {
			const itemToPush = isOverWordLimit ? (
				<RovoChatHighlightActions
					selectedText={selectedText}
					product="confluence"
					onClickChat={handleClickChat}
				/>
			) : (
				createReadingAidsDefineButton({ selectedRange, selectedText, isOverWordLimit })
			);
			baseComponents.push(itemToPush);
		} else {
			if (showReadingAidsDefineButton) {
				baseComponents.push(
					createReadingAidsDefineButton({ selectedRange, selectedText, isOverWordLimit }),
				);
			}
		}

		const builtInComponents = builtInWebItems.map((item) => {
			const { completeKey } = item;
			if (
				completeKey ===
				'com.atlassian.confluence.plugins.confluence-jira-content:create-JIRA-issue-summary'
			) {
				return createJiraIssueButton(completeKey);
			}
		});

		return (
			<BuiltInActions key="built-in-actions">
				{getComponentsWithDivider([...baseComponents, ...builtInComponents], hasOtherActions)}
			</BuiltInActions>
		);
	};

	const getComponentsForPopup = (
		webItems,
		forgeItems: ReadonlyArray<ForgeUIExtensionType> = [],
	) => {
		const builtInWebItems = webItems.filter((item) => BUILT_IN_ITEMS.includes(item.completeKey));
		const connectItems = webItems.filter(
			(item) => !DROPDOWN_EXCLUDED_ITEMS.includes(item.completeKey),
		);
		const hasOtherActions = forgeItems?.length || connectItems?.length;

		return [
			renderBuiltInWebItemActions(builtInWebItems, hasOtherActions),
			<OtherActions
				key="other-components"
				connectItems={connectItems}
				forgeItems={forgeItems}
				selectForgeExtension={(forgeExtension) => {
					const selectedRange = getRange();
					if (!selectedRange) {
						return;
					}

					const selectedText = textNormalize(String(selectedRange)).trim();

					if (!selectedText) {
						return;
					}

					setActionStates((prevState) => ({
						...prevState,
						forgeExtension,
						activeSelection: {
							selectedText,
							selectedRange,
						},
					}));
				}}
			/>,
		];
	};

	const renderPopup = (components) => {
		return components.length ? (
			<Subscribe to={[BannerStateContainer]}>
				{(bannerState: BannerStateContainer) => {
					// if there's a sticky header we want to position the toolbar below
					// the nav, the page header (if visible) and the sticky header
					let stickyHeaderHeight = 0;
					const range = getRange();
					if (range) {
						const header = findStickyHeader(range.commonAncestorContainer);
						if (header && bannerState) {
							stickyHeaderHeight = bannerState.getTotalHeight() + header.clientHeight;
						}
					}

					if (!screenEventFiredRef.current) {
						createAnalyticsEvent({
							type: 'sendScreenEvent',
							data: {
								name: 'highlightActionsMenu',
								attributes: {
									contentId,
								},
							},
						}).fire();
					}
					screenEventFiredRef.current = true;

					return (
						<Fragment>
							{position ? (
								<Popup top={stickyHeaderHeight + position.top} left={position.left}>
									{components}
								</Popup>
							) : (
								<Popup top={stickyHeaderHeight + top} left={left}>
									{components}
								</Popup>
							)}

							<ExperienceSuccess name={VIEW_PAGE_CONTEXT_MENU_EXPERIENCE} />
						</Fragment>
					);
				}}
			</Subscribe>
		) : null;
	};

	const isCreateSingleJiraIssueHandleParams = (
		params: CreateIssueRecordedProps,
	): params is CreateSingleJiraIssueHandleParams => {
		return (params as CreateSingleAiJiraIssueHandleParams)?.fireAiAnalytics === undefined;
	};

	const handleConfirm = useCallback(async () => {
		const callRightSidebarClose = async () => rightSidebarContext.close(ISSUE_CREATE_SIDE_PANEL_ID);

		// Need to make sure sidebar is closed before creating a new one issue
		await callRightSidebarClose();

		// Sorry this block sucks because of TS types
		if (isCreateSingleJiraIssueHandleParams(recordedClick)) {
			createSingleJiraIssueHandle(recordedClick);
		} else {
			recordedClick && createSingleAiJiraIssueHandle(recordedClick);
		}

		setIsWarningDialogOpen(false);
	}, [setIsWarningDialogOpen, recordedClick, rightSidebarContext]);

	const handleCancel = useCallback(() => {
		setIsWarningDialogOpen(false);
	}, [setIsWarningDialogOpen]);

	return (
		<Fragment>
			{isWarningDialogOpen && (
				<IssueCreateWarningDialog onConfirm={handleConfirm} onCancel={handleCancel} />
			)}
			{/* Using LazyForgeUIExtensions to preload extension list. High chance that we will have the list of
            extension points before user initiate context menu and click dropdown button
         */}
			{contentStatusError && <ErrorDisplay error={contentStatusError} />}
			{contentQueryError && <ErrorDisplay error={contentQueryError} />}
			<LazyForgeUIExtensions
				moduleType={FORGE_MODULE_CONTEXT_MENU}
				render={(_apps, _loading, error) => {
					if (error && !isErrorMarkedAsHandled(error)) {
						return <ErrorDisplay error={error} />;
					}
					return null;
				}}
			/>
			{forgeExtension && activeSelection && !show && (
				<ForgeAction
					content={content.current}
					contentId={contentId}
					forgeExtension={forgeExtension}
					activeSelection={activeSelection}
					onClose={() => {
						setActionStates((prevState) => ({
							...prevState,
							forgeExtension: null,
						}));
					}}
				/>
			)}

			{isReadingAidsOpen && activeSelection && !show && (
				<ReadingAidsPopup
					selectedText={activeSelection.selectedText}
					selectionRange={activeSelection.selectedRange}
					selectionRect={selectionRect}
					isReadingAidsOpen={isReadingAidsOpen}
					onClose={() => {
						void setDefineDefinitionOpen({ defineDefinitionOpen: false });

						setActionStates((prevState) => ({
							...prevState,
							isReadingAidsOpen: false,
						}));
					}}
					contentId={contentId}
					popupPortalContainerId={popupPortalContainerId}
					onResult={(result) => {
						if (readingAidsChangeboardingStep === 'idle') {
							if (result?.nlpSearch?.errorState?.startsWith('NO_ANSWER')) {
								setActionStates((prevState) => ({
									...prevState,
									isReadingAidsOpen: false,
									readingAidsChangeboardingStep: 'modal-undefined',
								}));
							} else {
								stopReadingAidsChangeboarding();
							}
						}
					}}
				/>
			)}

			<ModalTransition>
				{readingAidsChangeboardingStep.startsWith('modal') ? (
					<ReadingAidsChangeboardingModal
						onNextClick={() => {
							setActionStates((prevState) => ({
								...prevState,
								readingAidsChangeboardingStep: 'spotlight',
								show: true,
							}));
						}}
						modalVersion={readingAidsChangeboardingStep as 'modal-undefined' | 'modal-word-count'}
						contentId={contentId}
					/>
				) : null}
			</ModalTransition>

			<SpotlightTransition>
				{readingAidsChangeboardingStep === 'spotlight' ? (
					<ReadingAidsChangeboardingSpotlight
						contentId={contentId}
						onGotItClick={stopReadingAidsChangeboarding}
					/>
				) : null}
			</SpotlightTransition>

			{!isFabric && (
				<HighlightActionsContext.Provider value={highlightActionsContextValue}>
					<div key="content-container" ref={content}>
						{children}
					</div>
				</HighlightActionsContext.Provider>
			)}

			<ErrorBoundary attribution={Attribution.BACKBONE}>
				{show ? (
					<Fragment>
						<ExperienceStart id={contentId} name={VIEW_PAGE_CONTEXT_MENU_EXPERIENCE} />
						<WebItemLocation
							key="action-panel"
							contentId={contentId}
							location={ACTION_PANEL}
							fetchPolicy="cache-first"
						>
							{({ webItems }) => (
								<LazyForgeUIExtensions
									moduleType={FORGE_MODULE_CONTEXT_MENU}
									render={(forgeItems, loading, error) => {
										if (loading) {
											return null;
										}
										if (error) {
											if (!isErrorMarkedAsHandled(error)) {
												return <ErrorDisplay error={error} />;
											}
											return null;
										}

										return renderPopup(getComponentsForPopup(webItems, forgeItems));
									}}
								/>
							)}
						</WebItemLocation>
					</Fragment>
				) : null}
			</ErrorBoundary>
		</Fragment>
	);
};
